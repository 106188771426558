import { useCallback, useEffect, useState } from "react";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { TablaHistorialPagos } from "./TablaHistorialPagos";
import { useSelector } from "react-redux";
import "./metodospago.css";
import { FiltrosHistorialPagos } from "./FiltrosHistorialPagos";
import { set } from "date-fns";

const URL_GET_HISTORIAL_PAGOS = "flotas/historial";

export const HistorialPagos = () => {
  const { t } = useTranslation();
  const { token, nombre, apellido } = useSelector((state) => state.auth);
  const [historial, setHistorial] = useState([]);
  const [loading, setLoading] = useState(false);

  const orderData =
    (asc, index, pagos = null) =>
    () => {
      let data;
      if (!pagos) {
        data = [...historial];
      } else {
        data = [...pagos];
      }

      switch (index) {
        case 0:
          index = "cuenta";
          break;
        case 1:
          index = "cantidad";
          break;
        case 2:
          index = "concepto";
          break;
        case 3:
          index = "fecha";
          break;
        case 4:
          index = "tarjeta";
          break;
        case 5:
          index = "marca";
          break;
        default:
          break;
      }

      if (asc) {
        data.sort((a, b) => {
          if (a[index] < b[index]) {
            return -1;
          }
          if (a[index] > b[index]) {
            return 1;
          }
          return 0;
        });
      } else {
        data.sort((a, b) => {
          if (a[index] > b[index]) {
            return -1;
          }
          if (a[index] < b[index]) {
            return 1;
          }
          return 0;
        });
      }
      setHistorial(data);
    };

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <Col sm={12} lg={{ span: 8, order: 1 }}>
              <h1>{t("pago.historial.h1")}</h1>
            </Col>
            <Col
              sm={12}
              lg={{ span: 4, order: 2 }}
              className="filter-select-filters facturas"
            >
              <FiltrosHistorialPagos
                url={URL_GET_HISTORIAL_PAGOS}
                token={token}
                historial={historial}
                setHistorial={setHistorial}
                loading={loading}
                setLoading={setLoading}
              />
            </Col>
          </Row>
        </Container>
        <Container className="mt-5 py-1">
          <Row className="content-section">
            {!loading ? (
              historial && historial.length > 0 ? (
                <TablaHistorialPagos
                  data={historial}
                  token={token}
                  orderData={orderData}
                />
              ) : (
                <div className="text-center">
                  <h3>{t("pago.historial.no-result")}</h3>
                </div>
              )
            ) : (
              <LoaderPetroprix />
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
