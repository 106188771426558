import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import logout_icon from "../../assets/images/logout-icon.svg";
import config_icon from "../../assets/images/config-icon.svg";
import { FlotasSelection } from "../flotas/FlotasSelection";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFlota,
  continueTourUser,
  resumeTourUser,
  startLogout,
} from "../../store/auth";
import { ModalCambiarPassword } from "./ModalCambiarPassword";
import { HelpInfo } from "./HelpInfo";

export const Header = (data = { name: "Usuario" }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const { flotas, cuentas, nombre, apellido, flotaActiva, username, token } =
    useSelector((state) => state.auth);

  const cuentaPersonal = cuentas.find((cuenta) => cuenta.personal === "1");

  const dispatch = useDispatch();

  const selectFlotaRef = useRef(null);

  const onLogout = () => {
    dispatch(startLogout());
  };

  const cambiaFlota = (flota, token) => {
    dispatch(changeFlota(flota, token));
  };

  const pathname = location.pathname.split("/");
  const ruta = pathname[pathname.length - 1];

  const [modalPassword, setModalPassword] = useState({
    show: false,
    iduser: null,
  });

  // Creamos un efecto para saber cuando esta el select de flotas cargado
  useEffect(() => {
    if (selectFlotaRef.current) {
      //Mostramos por consola que el select esta cargado
      dispatch(resumeTourUser());
    }
  }, [selectFlotaRef]);

  return (
    <>
      <header className="d-none d-lg-block">
        {/* comprobamos que la ruta sea flotas pero no este dentro de metodos-pago */}
        {/* 
        {flotas === "1" &&
          ((location.pathname.startsWith("/flotas") &&
            ruta !== "historial" &&
            ruta !== "metodos-pago" &&
            ruta !== "nueva-tarjeta" &&
            ruta !== "nuevo-vehiculo") ||
            location.pathname.startsWith("/reabastecimento") ||
            location.pathname.startsWith("/repostajes")) && (
            <span className="step5">
              <FlotasSelection
                flotaActiva={flotaActiva}
                onChange={cambiaFlota}
                flotas={cuentas}
                token={token}
                ref={selectFlotaRef}
              />
            </span>
          )} */}
        <Container className="header-container">
          <HelpInfo />
          <Dropdown className="user-options" align={{ lg: "end" }}>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className=" step1"
            >
              {/* <img
              className="user-exit"
              src={exit_icon}
              alt={t("header.exit-icon.text")}
            /> */}
              <span className="username">
                {nombre?.toLowerCase() + " " + apellido?.toLowerCase()}
                <span className="user-email">{username}</span>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="header-user-menu">
              {/* {flotas === "1" && (
              <>
                {cuentaPersonal?.tipo_pago === "prepago" && (
                  <Dropdown.Item href="/flotas/metodos-pago">
                    {t("header.user-menu.metodos-pago")}
                  </Dropdown.Item>
                )}
                <Dropdown.Item href="/flotas/historial">
                  {t("header.user-menu.historial")}
                </Dropdown.Item>
              </>
            )} */}
              <Dropdown.Item
                onClick={() =>
                  setModalPassword({
                    show: true,
                    iduser: null,
                  })
                }
              >
                {t("header.user-menu.password")}
              </Dropdown.Item>
              <Dropdown.Divider className="hr-no-heigth" />
              <Dropdown.Item onClick={onLogout} className="logout-item">
                {t("header.user-menu.salir")}
                <Button
                  variant="light"
                  className="user-exit"
                  onClick={onLogout}
                >
                  <img
                    src={logout_icon}
                    className="user-exit-img"
                    alt={t("header.exit-icon.text")}
                  />
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
        {/* <Button variant="light" className="user-config" href="/datos-acceso">
          <img src={config_icon} alt={t("header.config-btn.text")} />
        </Button> */}
      </header>

      <ModalCambiarPassword
        show={modalPassword.show}
        onHide={() => {
          setModalPassword({ show: false });
        }}
        iduser={modalPassword.iduser}
      />
    </>
  );
};
