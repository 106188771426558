import { useCallback, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Menu } from "../ui/Menu";
import { Header } from "../ui/Header";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

import promotions_empty from "../../assets/images/promos/promotions-empty.png";
import google_play_logo from "../../assets/images/promos/google-play-logo.svg";
import app_store_logo from "../../assets/images/promos/app-store-logo.svg";

import "./promociones.css";
const URL_GET_PROMOCIONES = "campanias";

export const PromocionesScreen = () => {
  const [mostrarPromo, setMostrarPromo] = useState(1);
  const [promotions, setPromotions] = useState([]);
  const [modalShowPromotion, setModalShowPromotion] = useState(false);
  const [loading, setLoading] = useState(true);
  const { token, nombre, apellido } = useSelector((state) => state.auth);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchPromotions = useCallback(async () => {
    setLoading(true);
    setPromotions([]);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_PROMOCIONES, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        setLoading(false);
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setPromotions(data);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  const handleClickPromo = (url) => {
    window.open(url, "_blank");
  };

  // Actualizamos el estado de vehiculos cada vez que se añade uno nuevo
  useEffect(() => {
    fetchPromotions();
  }, [fetchPromotions]);

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <Col xs={12} sm={12} lg={{ span: 9, order: 1 }}>
              <h1 className="d-none d-md-block">{t("promos.h1.desktop")}</h1>
              <h1 className="d-block d-md-none">{t("promos.h1.mobile")}</h1>
            </Col>
            {/* {promotions && promotions.length > 0 && (
              <SelectCampanias
                mostrarPromo={mostrarPromo}
                setMostrarPromo={setMostrarPromo}
              />
            )} */}
          </Row>
        </Container>
        <Container className="py-1 step11">
          <Row className="content-section">
            {!loading ? (
              promotions && promotions.length > 0 ? (
                promotions.map((promo) => (
                  <Col xs={12} md={6} className="promo-content" key={promo.id}>
                    <Image
                      className="promo-img"
                      key={promo.id}
                      src={`data:image/jpeg;base64,${promo.nuevo_banner}`}
                      alt={promo.nombre}
                      fluid={true}
                      // onClick={() => setModalShowPromotion(true)}
                      onClick={() => {
                        handleClickPromo(promo.bases_url);
                      }}
                    />
                    {/* <ModalInfoPromo
                      show={modalShowPromotion}
                      onHide={() => setModalShowPromotion(false)}
                      id={promo.id}
                      nombre={promo.nombre}
                      info={promo.descripcion}
                    /> */}
                  </Col>
                ))
              ) : (
                <>
                  <Row>
                    <Col xs={12} className="text-center">
                      <Image
                        src={promotions_empty}
                        fluid={true}
                        key="empty-img"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <p className="prom-info-text">
                        {t("promos.empty.p")}
                        <br />
                        {t("promos.empty.p-2")}
                      </p>
                    </Col>
                  </Row>
                  <Row className="prom-download-logos mt-5">
                    <Col xs={12}>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.petroprix.petroprixapp.pt&hl=pt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image src={google_play_logo} key="google-play-logo" />
                      </a>
                      <a
                        href="https://apps.apple.com/es/app/petroprix/id6499206590"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image src={app_store_logo} key="app-store-logo" />
                      </a>
                    </Col>
                  </Row>
                </>
              )
            ) : (
              <LoaderPetroprix />
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
