import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

export const FlotaStepSix = ({
  prevStep,
  nextStep,
  handleFormData,
  values,
}) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  const lang = i18n.language.toUpperCase();
  const url_iframe =
    "https://api.paycomet.com/gateway/ifr-bankstore?MERCHANT_MERCHANTCODE=rgf6xc5s&MERCHANT_TERMINAL=22240&OPERATION=107&LANGUAGE=" +
    lang +
    "&MERCHANT_MERCHANTSIGNATURE=7a91392104a4493e4b9b07eada1e4d27dbdf6e5dbfdf06b7b948a58bb1da0a30b11ae25bee8b0b915a396a95a8ca4f31bb622d98f9fd27eeb755d2e7ef4aa8f2&MERCHANT_ORDER=CRX-197";

  const { t } = useTranslation();

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to step 2
    // if (values.cantidad === ''){
    //   setError(true);
    // } else {
    nextStep();
    // }
  };

  if (
    (values.tipoPago === "Crédito" || values.tipoPago === "Credit") &&
    values.cantidad > 400
  ) {
    return nextStep();
  } else {
    return (
      <>
        <h1 className="flotas-registro">
          {t("flotas.registro-flota.step-6.h1")}
        </h1>
        {(values.tipoPago === "Crédito" || values.tipoPago === "Credit") &&
          values.cantidad <= 400 && (
            <>
              <p>
                {t("flotas.registro-flota.step-6.p")}
                <b>300€</b>
              </p>
            </>
          )}
        <Row>
          <iframe
            title="titulo"
            id="iframe_content"
            width="400"
            height="300"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            style={{
              border: "0px solid #000000",
              margin: "0px",
              "margin-inline": "auto",
              "padding-left": "10%",
            }}
            src={url_iframe}
          ></iframe>
          <Button
            variant="outline-primary back-btn-tarjeta"
            size="lg"
            name="datos-tarjeta"
            onClick={prevStep}
          >
            {t("flotas.registro-flota.btn-back")}
          </Button>
        </Row>
      </>
    );
  }
};
