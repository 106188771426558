import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import notification_icon from "../../assets/images/notification-icon.svg";
import { ModalControlKM } from "./ModalControlKM";
import { useTranslation } from "react-i18next";
import { SwitchControlKm } from "../ui/SwitchControlKm";
import { useSelector } from "react-redux";

export const FlotasActions = ({ controlKM, setControlKM }) => {
  // const [notifications, setNotifications] = useState(true);
  const { flotaActiva } = useSelector((state) => state.auth);
  const [modalControlShow, setModalControlShow] = useState(false);
  const [buttonKMValue, setButtonKMValue] = useState(
    flotaActiva?.km === 1 ? true : false
  );

  const { t } = useTranslation();

  useEffect(() => {}, [controlKM]);

  return (
    <>
      <Row className="mt-1 flotas-buttons">
        {flotaActiva.permisos && flotaActiva.activo === "1" ? (
          <>
            <Col xs={{ span: 4, order: 3 }} className="text-right">
              {flotaActiva.tipo_pago === "prepago" && (
                <Button
                  variant="primary"
                  className="flotas-btn-add"
                  href="agregar-saldo"
                >
                  {t("flotas.actions.add-saldo")}
                </Button>
              )}
            </Col>
            <Col xs={{ span: 4, order: 2 }} className="text-right">
              <Button
                href="nuevo-vehiculo"
                variant="primary"
                className="flotas-btn-add"
              >
                {t("flotas.actions.add-vehiculo")}
              </Button>
            </Col>

            <Col xs={{ span: 3, order: 1 }} className="text-right">
              <SwitchControlKm
                controlkm={controlKM}
                setControlKM={setControlKM}
                onChange={setControlKM}
                modalControlShow={modalControlShow}
                setModalControlShow={setModalControlShow}
              />
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>

      <ModalControlKM
        show={modalControlShow}
        onChange={setControlKM}
        controlkm={controlKM}
        value={controlKM}
        onHide={() => setModalControlShow(false)}
      />
    </>
  );
};
