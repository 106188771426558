import { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Navbar,
  Row,
} from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import login_img from "../../assets/images/login-img.svg";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import Feedback from "react-bootstrap/esm/Feedback";
import "animate.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { MiniLoader } from "../ui/MiniLoader";
import { HelpInfo } from "../ui/HelpInfo";
import axiosApi from "../../axios/axios-api";

export const RegistroScreen = () => {
  const [validated, setValidated] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [numLogins, setNumLogins] = useState(0);
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const [captchaValid, setCaptchaValid] = useState(null);
  const captchaRef = useRef(null);
  const [messageFeedback, setMessageFeedback] = useState("");
  const { t } = useTranslation();

  const [telefono, setTelefono] = useState(t("ayuda.telefono"));
  const [email, setEmail] = useState(t("ayuda.email"));

  const navigate = useNavigate();

  const onChange = () => {
    if (captchaRef.current.getValue()) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    setMessageFeedback("");
    setValidated(false);
    setErrors(false);
    setNumLogins(numLogins + 1);

    const username = document.getElementById("email");

    event.preventDefault();
    event.stopPropagation();

    let dataValid = {
      username: false,
    };

    if (validatedEmail(username.value) === false) {
      username.setCustomValidity(" ");
      dataValid.username = false;
    } else {
      username.setCustomValidity("");
      dataValid.username = true;
    }

    if (!dataValid.username) {
      setValidated(true);
      setLoading(false);
      return;
    } else {
      setValidated(true);

      if (!captchaValid) {
        setCaptchaValid(false);
        setLoading(false);
        return;
      }

      let data = {
        email: username.value.trim(),
        captcha: captchaRef.current.getValue(),
        source: 1,
      };

      try {
        if (process.env.REACT_APP_URL_VALIDATION_SERVICE) {
          const headers = {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_VALIDATION_SERVICE_API_KEY,
          };
          const con = axios.create({
            baseURL: process.env.REACT_APP_URL_VALIDATION_SERVICE,
            headers,
          });
          const response = await con.post("", data, headers);
          if (response?.data?.statusCode === 200) {
            setValidated(false);
            username.value = "";
            setCompleted(true);
            setErrors(false);
            setMessageFeedback(response.data.statusDescription);
            setLoading(false);
            //Navegamos a la pantalla de envio de email y pasamos por props el email
            navigate("/registo/email-enviado", {
              state: { email: data.email },
            });
          } else {
            setErrors(true);
            setCompleted(false);
            setMessageFeedback(response.data.statusDescription);
            setCaptchaValid(null);
            setLoading(false);
            console.warn(response.data.statusDescription);
          }
        }
      } catch (error) {
        console.error(error);
        setValidated(false);
        setErrors(true);
        setMessageFeedback(error.message);
        setCompleted(false);
        setCaptchaValid(null);
        setLoading(false);
      } finally {
        if (captchaRef.current) {
          captchaRef.current.reset();
        }
      }
    }
  };

  const validatedEmail = (email) => {
    const re = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    return () => {
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    };
  }, []);

  const fetchInfoContacto = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API) {
        const response = await axiosApi.get("infoPetroprix", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setTelefono(data.telefono);
          setEmail(data.email_informacion);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInfoContacto();
  }, []);

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="menu px-1"
          style={{ display: "flex" }}
        >
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top"
              alt={t("login.menu.text")}
            />
          </Navbar.Brand>
          <HelpInfo telefono={telefono} email={email} />
        </Navbar>
      </Container>
      <Container className="content-login-page">
        <Container className="login-header-container">
          <header className="d-none d-lg-block">
            <img
              src={petroprix_logo}
              className="d-inline-block align-left login-page-logo"
              alt={t("login.menu.text")}
            />
          </header>
          <HelpInfo telefono={telefono} email={email} />
        </Container>
        <Container className="login-container py-1">
          <Row className="content-section login-section">
            <Col xs={12} lg={{ span: 12, order: 1 }}>
              <h1>
                {t("login.header.h1")}
                <span className="red-text">{t("login.header.h1.red")}</span>
              </h1>
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 1, order: 3 }}
              className="d-none d-lg-block"
            >
              <Image src={login_img} fluid="true" className="mt-5" />
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 2, order: 3 }}
              className="animate__animated animate__fadeInRight"
            >
              <h6 className="mt-5 go-back-link">
                <Link to={`/login`} className="no-decoration">
                  &lt; Volver
                </Link>{" "}
              </h6>
              <h2>{t("register.h2")}</h2>
              {completed && !errors && (
                <Alert variant="success-login">{messageFeedback}</Alert>
              )}

              {!completed && !errors && numLogins === 0 && (
                <Alert variant="primary-login">
                  {t("register.alert")}
                  <b>{t("register.alert.b-2")}</b>
                </Alert>
              )}
              {errors && !completed && (
                <Alert variant="danger-login">{messageFeedback}</Alert>
              )}

              {captchaValid === false && (
                <Alert variant="danger-login">
                  <b>{t("login.form.captcha.not-valid")}</b>
                </Alert>
              )}
              <Form
                noValidate
                validated={validated}
                className="login-form"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <FloatingLabel
                  label={t("register.form.email")}
                  className="mt-3"
                >
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder={t("register.form.email")}
                    required
                  />
                  <Feedback type="invalid">
                    {t("register.form.email.invalid")}
                  </Feedback>
                </FloatingLabel>

                <div className="d-flex mt-3 recaptcha">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={SITE_KEY}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="d-grid gap-2 mt-5">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className="form-login-btn"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? <MiniLoader /> : t("login.form.btn-registrar")}
                  </Button>
                  <p className="login-help-text mt-3">
                    {t("login.help.text")}{" "}
                    <a
                      href={`mailto:${email}`}
                      className="login-help-text-link"
                    >
                      {email}
                    </a>
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
