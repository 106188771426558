import { useCallback, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_DELETE_VEHICULOS_FLOTA = "flotas/deleteVehiculo/";

export function ModalBorrarVehiculo(props) {
  const { vehiculo } = props;
  const [loading, setLoading] = useState(false);
  const { token, flotaActiva } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const normalizarMatricula = (mat) => {
    if (!mat) return "";

    let matriculaNormalizada = mat?.split("-");
    matriculaNormalizada =
      matriculaNormalizada[matriculaNormalizada.length - 1];

    return matriculaNormalizada;
  };

  const deleteVehicle = async () => {
    setLoading(true);
    let checkbox = document.getElementById(
      "modal-eliminar-vehiculo-flota-personales-" + vehiculo?.matricula
    );

    let data = {
      idCcp: flotaActiva.id_cuenta,
      confirmacion: checkbox.checked ? 1 : 0,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.delete(
          `${URL_DELETE_VEHICULOS_FLOTA}${vehiculo.idTar}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            data: data,
          }
        );
        if (response?.data?.code === 200) {
          // cerramos el modal
          props.onHide(true, checkbox.checked ? 1 : 0);
          toast(t("datos.modal.borrar.result.ok"), {
            icon: <LogoPetroprix />,
          });
        } else {
          props.onHide(false);
          toast(response?.data?.message, {
            icon: <LogoPetroprix />,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="py-5">
        <Modal.Body>
          <h3 className="title-modal">
            {t("flotas.modal.eliminar.pregunta.1")}
            {normalizarMatricula(vehiculo?.matricula)}
            {t("flotas.modal.eliminar.pregunta.2")}
          </h3>
          <div className="d-grid mt-3 flotas-modal-eliminar-pregunta">
            <Form.Group
              className=" mb-0"
              controlId={
                "modal-eliminar-vehiculo-flota-personales-" +
                vehiculo?.matricula
              }
            >
              <Form.Check
                type="checkbox"
                label={t("flotas.modal.eliminar.checkbox")}
                value={1}
              />
            </Form.Group>
          </div>

          <div className="d-grid gap-2  mt-4">
            <Button
              variant="outline-primary"
              size="lg"
              onClick={() => props.onHide(false, false)}
            >
              {t("flotas.modal.eliminar.btn-cancel")}
            </Button>
            <Button variant="primary" size="lg" onClick={deleteVehicle}>
              {t("flotas.modal.eliminar.btn-confirmar")}
            </Button>
          </div>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
