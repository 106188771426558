import {
  Button,
  Col,
  Container,
  Dropdown,
  Image,
  Navbar,
  Row,
} from "react-bootstrap";
import error_img from "../../assets/images/error-img.svg";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import exit_icon from "../../assets/images/exit-icon.svg";
import config_icon from "../../assets/images/config-icon.svg";
import "./error404.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const Error404Screen = () => {
  const [logged, setLogged] = useState(false); /* CAMBIAR POR AUTHCONTEXT */

  const { t } = useTranslation();

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar collapseOnSelect expand="lg" className="menu px-1">
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top menu-logo-mobile"
              alt={t("menu.logo.text")}
            />
          </Navbar.Brand>
        </Navbar>
      </Container>
      <Container className="content-error-page">
        <Row>
          <header className="d-none d-lg-block">
            <img
              src={petroprix_logo}
              className="d-inline-block align-left error-page-logo"
              alt="Petroprix | Zona clientes"
            />
            {logged && (
              <>
                <img className="user-exit" src={exit_icon} alt="Salir" />
                <span className="username">Julián González</span>
                <Dropdown className="user-options">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                  ></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/flotas/metodos-pago">
                      {t("header.user-menu.metodos-pago")}
                    </Dropdown.Item>
                    <Dropdown.Item href="/flotas/historial">
                      {t("header.user-menu.historial")}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>{t("header.user-menu.salir")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button variant="light" className="user-config">
                  <img src={config_icon} alt={t("header.config-btn.text")} />
                </Button>
              </>
            )}
          </header>
        </Row>
        <Row>
          <Container className="py-1">
            <Row className="content-section error-section">
              <Col
                xs={12}
                lg={{ span: 5, offset: 1, order: 2 }}
                className="error-text"
              >
                <h2>{t("error404.h2")}</h2>
                <p>
                  {t("error404.info-text.black")}
                  <span className="error-text-red">
                    {t("error404.info-text.red")}
                  </span>
                </p>
              </Col>
              <Col xs={12} lg={{ span: 4, offset: 2, order: 1 }}>
                <Image src={error_img} fluid="true" />
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
};
