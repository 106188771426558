import { useEffect, useState } from "react";
import { Alert, Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import axiosApi from "../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

export const FlotasInfo = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { token, flotaActiva } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [saldos, setSaldos] = useState({
    saldoTotal: "-",
    saldoRestante: "-",
    saldoUsado: "-",
  });

  const fetchSaldosFlota = async (id) => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(`flota/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setSaldos({
            saldoTotal: data.Saldo_total,
            saldoRestante: data.Saldo_restante,
            saldoUsado: data.Saldo_usado,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchSaldosFlota(flotaActiva.id_cuenta);
    }
  }, [flotaActiva]);

  const formatTextTipoFlota = (flota) => {
    let tipoFlota = "";
    if (flota.tipo_pago === "prepago") {
      tipoFlota = t("flotas.tipo-cuenta.prepago");
    } else {
      tipoFlota = t("flotas.tipo-cuenta.credito");
    }

    if (flota.tipo_credito !== null && flota.tipo_credito != -1) {
      if (flota.tipo_credito === "1") {
        tipoFlota += " (" + t("flotas.tipo-cuenta.transferencia") + ")";
      } else if (flota.tipo_credito === "0") {
        tipoFlota += " (" + t("flotas.tipo-cuenta.b2b") + ")";
      }
    }

    return tipoFlota;
  };

  return (
    <>
      {location.pathname.startsWith("/flotas") && (
        <>
          <span className="flotas-info-tipo-cuenta">
            {formatTextTipoFlota(flotaActiva)}
          </span>
          <div className="flotas-info-tags d-xs-block">
            <Alert key="1" variant="danger">
              <span className="flotas-info-tag-title">
                {t("flotas.info.saldo-total")}
              </span>
              <span className="flotas-info-tag-number">
                {saldos.saldoTotal} <span className="thin">€</span>
              </span>
            </Alert>
            <Alert key="2" variant="danger">
              <span className="flotas-info-tag-title">
                {t("flotas.info.saldo-restante")}
              </span>
              <span className="flotas-info-tag-number">
                {saldos.saldoRestante} <span className="thin">€</span>
              </span>
            </Alert>
            <Alert key="3" variant="danger">
              <span className="flotas-info-tag-title">
                {t("flotas.info.saldo-usado")}
              </span>
              <span className="flotas-info-tag-number">
                {saldos.saldoUsado} <span className="thin">€</span>
              </span>
            </Alert>
          </div>
        </>
      )}
    </>
  );
};
