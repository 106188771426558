import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import remove_icon from "../../assets/images/remove-icon.svg";
import "./metodospago.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import axiosApi from "../../axios/axios-api";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";
import "animate.css";

const URL_GET_TARJETAS = "paycomet/tarjetas";
const URL_PUT_TARJETA_PRINCIPAL = "paycomet/tarjetaPrincipal";
const URL_DELETE_TARJETA = "paycomet/tarjeta";

export const MetodosPagoScreen = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { token, nombre, apellido, cuentas } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const [listadoTarjetas, setListadoTarjetas] = useState([]);

  const fetchTarjetas = useCallback(async () => {
    setLoading(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(`${URL_GET_TARJETAS}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setListadoTarjetas(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  // Actualizamos el estado de vehiculos cada vez que se añade uno nuevo
  useEffect(() => {
    fetchTarjetas();
  }, [fetchTarjetas]);

  const changePrincipal = async ({ target }) => {
    const id_tarjeta_paycomet = target.value;
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.put(
          `${URL_PUT_TARJETA_PRINCIPAL}/${id_tarjeta_paycomet}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response?.data?.code === 200) {
          toast(t("pago.metodos.favorita.result.ok"), {
            icon: <LogoPetroprix />,
          });
        } else {
          toast(t("pago.metodos.favorita.result.error"), {
            icon: <LogoPetroprix />,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const borrarTarjeta = async (id) => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.delete(`${URL_DELETE_TARJETA}/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: {},
        });
        if (response?.data?.code === 200) {
          toast(t("pago.metodos.borrar.result.ok"), {
            icon: <LogoPetroprix />,
          });
          let tarjetas = listadoTarjetas.filter(
            (tarjeta) => tarjeta.id_tarjeta_paycomet !== id
          );
          if (tarjetas !== listadoTarjetas) {
            let tarjeta = document.getElementById("tarjeta-" + id);
            tarjeta.classList.add("animate__animated");
            tarjeta.classList.add("animate__backOutLeft");
            tarjeta.addEventListener("animationend", () => {
              tarjeta.classList.remove("animate__animated");
              tarjeta.classList.remove("animate__backOutLeft");
              tarjeta.classList.add("d-none");
            });

            // Marcamos la primera tarjeta de la lista como principal
            let id_tarjeta_paycomet = tarjetas[0].id_tarjeta_paycomet;
            let tarjetaPrincipal = document.getElementById(
              "tarjeta-" + id_tarjeta_paycomet
            );
            let inputPrincipal = tarjetaPrincipal.querySelector(
              "input[name=principal]"
            );
            inputPrincipal.checked = true;
          }
        } else {
          toast(t("pago.metodos.borrar.result.error"), {
            icon: <LogoPetroprix />,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <Col xs={12} sm={12} lg={{ span: 9, order: 1 }}>
              <h1>{t("pago.metodos.h1")}</h1>
            </Col>
          </Row>
        </Container>
        {!loading ? (
          <>
            <Container className="py-1">
              <Row className="content-section">
                <Col xs={12} lg={6}>
                  {listadoTarjetas?.length > 0 ? (
                    listadoTarjetas?.map((tarjeta, index) => (
                      <Row
                        key={index}
                        id={"tarjeta-" + tarjeta?.id_tarjeta_paycomet}
                      >
                        <div className="pagos-info-tarjeta">
                          <div className="pagos-default">
                            <InputGroup.Radio
                              name="principal"
                              value={tarjeta?.id_tarjeta_paycomet}
                              aria-label={t("pago.metodos.btn-favorita")}
                              className="datos-btn-favorito"
                              onClick={changePrincipal}
                              defaultChecked={
                                tarjeta?.principal === 1 ? true : false
                              }
                            />
                          </div>
                          <div className="pagos-datos">
                            <div className="pagos-datos-content">
                              <span className="pagos-tipo-tarjeta">
                                {tarjeta?.card_brand} {tarjeta?.card_type}
                              </span>
                              <span className="pagos-num-tarjeta">
                                {tarjeta?.pan}
                              </span>
                              {tarjeta?.expiracion !== null &&
                                tarjeta?.expiracion !== "" && (
                                  <span className="pagos-caducidad">
                                    {tarjeta?.expiracion?.substring(5, 7)}/
                                    {tarjeta?.expiracion?.substring(2, 4)}
                                  </span>
                                )}
                            </div>
                          </div>
                          <div className="pagos-remove">
                            <Button
                              variant="light"
                              className="user-config"
                              onClick={() =>
                                borrarTarjeta(tarjeta?.id_tarjeta_paycomet)
                              }
                            >
                              <img
                                src={remove_icon}
                                className="pagos-remove-icon"
                                alt={t("pago.metodos.btn-borrar")}
                              />
                            </Button>
                          </div>
                        </div>
                      </Row>
                    ))
                  ) : (
                    <Row>
                      <div className="text-center">
                        <h6>{t("pago.metodos.no-result")}</h6>
                      </div>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
            <Container className="mt-1">
              <Row className="content-section">
                <Col xs={12} lg={5}>
                  <Button
                    variant="primary"
                    size="lg"
                    className="pagos-btn-nueva"
                    href="./nueva-tarjeta"
                  >
                    {t("pago.metodos.btn-nueva")}
                  </Button>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <LoaderPetroprix />
        )}
      </Container>
    </>
  );
};
