import axiosApi from "../../axios/axios-api";
import {
  changeCuentas,
  changeFlotaActiva,
  changeKm,
  checkingCredentials,
  finishTour,
  login,
  logout,
  nextStep,
  pauseTour,
  previousStep,
  resumeTour,
  startTour,
  updateDataClient,
} from "./";
import CryptoJS from "crypto-js";
import jwt from "jwt-decode";
import { ValidateToken } from "../../helpers/validateToken";

export const checkingAuthentication = (username, password) => {
  return async (dispatch) => {
    dispatch(checkingCredentials());
  };
};

export const startLogin = (username, password, captcha) => {
  return async (dispatch) => {
    dispatch(checkingCredentials());
    const params = JSON.stringify({
      username: username,
      pass: password,
      captcha: captcha,
    });
    if (
      process.env.REACT_APP_URL_API &&
      process.env.REACT_APP_KEY_OPENSSL_ENCRYPT &&
      process.env.REACT_APP_IV_OPENSSL_ENCRYPT
    ) {
      try {
        const response_login = await axiosApi.post("login", params, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response_login.status === 200) {
          if (response_login.data?.code === 200) {
            const { token } = response_login.data;
            const { data } = jwt(token);

            // Preparamos los datos para desencriptar el token
            const ciphertext = CryptoJS.enc.Base64.parse(data);
            const keyBytes = CryptoJS.enc.Utf8.parse(
              process.env.REACT_APP_KEY_OPENSSL_ENCRYPT
            );
            const ivBytes = CryptoJS.enc.Utf8.parse(
              process.env.REACT_APP_IV_OPENSSL_ENCRYPT
            );
            const params = {
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
              iv: ivBytes,
            };

            // Desencriptamos el token
            const decryptedBytes = CryptoJS.AES.decrypt(
              { ciphertext },
              keyBytes,
              params
            );

            // Obtenemos el objeto JSON de información del usuario del token
            const dataUser = JSON.parse(
              decryptedBytes.toString(CryptoJS.enc.Utf8)
            );

            // Obtenemos los datos del usuario
            const { flotas, cuentas_creditprix, tutorial } = dataUser;

            // Obtenemos los datos del cliente
            const response_client = await axiosApi.get("cliente", {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            });

            if (response_client.status === 200) {
              if (response_client.data?.code === 200) {
                const { id, username, nombre, apellido, nif } =
                  response_client.data.data;

                const sessionDuration = 1000 * 60 * 60 * 24 * 7; // 7 días
                const expires = new Date(Date.now() + sessionDuration);
                let cookie = `token=${token}; expires=${expires}; path=/`;

                // Comprobamos si la cookie supera los 4kb
                if (token.length > 4096) {
                  // Cortamos el token en partes de longitud 4kb y guardamos varias cookies para despues unirlas en el backend
                  const tokenParts = [];
                  const tokenLength = token.length;
                  const tokenPartLength = 3900;
                  let i = 0;

                  while (i < tokenLength) {
                    tokenParts.push(token.slice(i, i + tokenPartLength));
                    i += tokenPartLength;
                  }

                  // Guardamos las cookies
                  for (let i = 0; i < tokenParts.length; i++) {
                    if (i === 0) {
                      document.cookie = `token=${tokenParts[i]}; expires=${expires}; path=/`;
                    } else {
                      document.cookie = `petroprix${i}=${tokenParts[i]}; expires=${expires}; path=/`;
                    }
                  }
                } else {
                  document.cookie = cookie;
                }

                let telefono = null;
                let email_informacion = null;
                // Obtenemos la info de contacto de petroprix
                const response_info = await axiosApi.get("infoPetroprix", {
                  headers: {
                    "Content-Type": "application/json",
                  },
                });

                if (response_info.status === 200) {
                  telefono = response_info.data.data.telefono;
                  email_informacion = response_info.data.data.email_informacion;
                }

                // document.cookie = `token=${token}; expires=${expires}; path=/`;
                let flotaActiva =
                  cuentas_creditprix !== null ? cuentas_creditprix[0] : null;

                dispatch(
                  login({
                    id,
                    username,
                    token,
                    nombre,
                    apellido,
                    nif,
                    flotas,
                    cuentas_creditprix,
                    flotaActiva,
                    tutorial,
                    telefono,
                    email_informacion,
                  })
                );
              } else {
                dispatch(
                  logout({
                    error: response_client.data?.message,
                    status: "not-authenticated",
                  })
                );
              }
            } else {
              dispatch(logout({ error: null }));
            }
          } else {
            dispatch(
              logout({
                error: response_login.data.message,
                status: "not-authenticated",
              })
            );
          }
        } else {
          dispatch(logout({ error: null, status: "not-authenticated" }));
        }
      } catch (error) {
        // Maneja el error de inicio de sesión
        dispatch(logout({ error: error.message, status: "not-authenticated" }));
      }
    }
  };
};

export const startLogout = (message = null) => {
  return async (dispatch) => {
    // Borramos la cookie
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Comprobamos si existen cookies petroprix y las borramos
    const cookiesPetroprix = document.cookie
      .split("; ")
      .filter((row) => row.startsWith("petroprix"));

    if (cookiesPetroprix.length > 0) {
      cookiesPetroprix.forEach((cookie) => {
        document.cookie = `${
          cookie.split("=")[0]
        }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
    }

    // Borramos el localStorage
    localStorage.removeItem("user");
    localStorage.removeItem("persist:root");
    localStorage.removeItem("i18nextLng");
    dispatch(logout({ error: message }));
  };
};

export const changeFlota = (flotaActiva, token) => {
  return async (dispatch) => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get("flotas/permisos", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          dispatch(changeCuentas(response.data.data));
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].id_cuenta === flotaActiva.id_cuenta) {
              flotaActiva = response.data.data[i];
              break;
            }
          }
          dispatch(changeFlotaActiva(flotaActiva));
        }
      } else {
        dispatch(logout({ error: null, status: "not-authenticated" }));
      }
    } catch (error) {
      // Maneja el error de inicio de sesión
      let msgError = error.message;
    }
  };
};

export const changeKmFlota = (km) => {
  return async (dispatch) => {
    dispatch(changeKm(km));
  };
};

export const startTourUser = () => {
  return async (dispatch) => {
    dispatch(startTour());
  };
};

export const pauseTourUser = () => {
  return async (dispatch) => {
    dispatch(pauseTour());
  };
};

export const finishTourUser = () => {
  return async (dispatch) => {
    dispatch(finishTour());
  };
};

export const nextStepTourUser = () => {
  return async (dispatch) => {
    dispatch(nextStep());
  };
};

export const prevStepTourUser = () => {
  return async (dispatch) => {
    dispatch(previousStep());
  };
};

export const resumeTourUser = () => {
  return async (dispatch) => {
    dispatch(resumeTour());
  };
};

export const updateClientData = (data) => {
  return async (dispatch) => {
    dispatch(
      updateDataClient({
        nombre: data.nombre,
        apellido: data.apellido,
        nif: data.nif,
      })
    );
  };
};

export const completedTutorial = (token) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.post(
        "cliente/tutorial",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(finishTour());
      }
    } catch (error) {
      console.error(error);
    }
  };
};
