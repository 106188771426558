import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FlotaStepOne } from "./FlotaStepOne";
import { FlotaStepTwo } from "./FlotaStepTwo";
import "../flotas.css";
import { FlotaStepFive } from "./FlotaStepFive";
import { FlotaStepThree } from "./FlotaStepThree";
import { FlotaStepFour } from "./FlotaStepFour";
import { FlotaStepSix } from "./FlotaStepSix";
import { Menu } from "../../ui/Menu";
import { Header } from "../../ui/Header";
import { useTranslation } from "react-i18next";
import { ValidateToken } from "../../../helpers/validateToken";
import { useDispatch } from "react-redux";
import { startLogout } from "../../../store/auth";

const userdata = JSON.parse(localStorage.getItem("userdata"));
const nombre = userdata?.nombre;
const apellidos = userdata?.apellidos;

export const RegistroFlotaScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  //state for steps
  const [step, setStep] = useState(1);

  const { t } = useTranslation();

  //state for form data
  const [formData, setFormData] = useState({
    tipoPago: "",
    cantidad: "",
    nombre: "",
    apellidos: "",
    dni: "",
    direccion: "",
    localidad: "",
    provincia: "",
    codigoPostal: "",
    tipoFactura: "",
    prefijo: "",
    telefono: "",
    nombreEmpresa: "",
    nombreTitularCuenta: "",
    numeroIban: "",
    codigoSwift: "",
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = (e) => {
    let reset = e.target.name;
    if (reset === "cantidad" || step == 2) {
      setFormData((prevState) => ({
        ...prevState,
        cantidad: 0,
      }));
    } else if (reset === "facturacion" || step == 3) {
      setFormData((prevState) => ({
        ...prevState,
        nombre: "",
        apellidos: "",
        dni: "",
        direccion: "",
        localidad: "",
        provincia: "",
        codigoPostal: "",
        tipoFactura: "",
        prefijo: "",
        telefono: "",
      }));
    } else if (reset === "nombre-flota" || step == 5) {
      setFormData((prevState) => ({
        ...prevState,
        nombreEmpresa: "",
        nombreTitularCuenta: "",
        numeroIban: "",
        codigoSwift: "",
      }));
    }
    setStep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;
    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const header = (
    <Container>
      <Row className="page-header pb-4">
        <Col sm={12} lg={{ span: 5, order: 1 }}>
          <h1>
            {step == 1 ? (
              <Link to={`/flotas/mis-flotas`} className="no-decoration">
                &lt;
              </Link>
            ) : (
              <span onClick={prevStep} value={step} className="no-decoration">
                &lt;
              </span>
            )}
            {t("flotas.registro-flota.header.h1")}
          </h1>
        </Col>
      </Row>
    </Container>
  );

  // javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellidos} />
            {header}
            <Container>
              <Row className="content-section mb-4">
                <Col xs={12} lg={{ span: 6, offset: 3 }}>
                  <FlotaStepOne
                    nextStep={nextStep}
                    handleFormData={handleInputData}
                    values={formData}
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </>
      );
    // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellidos} />
            {header}
            <Container>
              <Row className="content-section mb-4">
                <Col xs={12} lg={{ span: 6, offset: 3 }}>
                  <FlotaStepTwo
                    prevStep={prevStep}
                    nextStep={nextStep}
                    handleFormData={handleInputData}
                    values={formData}
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </>
      );
    case 3:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellidos} />
            {header}
            <Container>
              <Row className="content-section mb-4">
                <FlotaStepThree
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={handleInputData}
                  values={formData}
                />
              </Row>
            </Container>
          </Container>
        </>
      );
    case 4:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellidos} />
            {header}
            <Container>
              <Row className="content-section mb-4">
                <FlotaStepFour
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={handleInputData}
                  values={formData}
                />
              </Row>
            </Container>
          </Container>
        </>
      );
    case 5:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellidos} />
            {header}
            <Container>
              <Row className="content-section mb-4">
                <FlotaStepFive
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={handleInputData}
                  values={formData}
                />
              </Row>
            </Container>
          </Container>
        </>
      );
    case 6:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellidos} />
            {header}
            <Container>
              <Row className="content-section mb-4">
                <FlotaStepSix
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleFormData={handleInputData}
                  values={formData}
                />
              </Row>
            </Container>
          </Container>
        </>
      );
    case 7:
      if (ValidateToken()) {
        return navigate("flotas/mis-flotas");
      } else {
        dispatch(startLogout("expired"));
        return false;
      }
    default:
      return <></>;
  }
};
