import { useCallback, useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { TablaGasolineras } from "./TablaGasolineras";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";

const URL_GET_VEHICULOS = "gasolineras";
const URL_GET_GASOLINERA_CLIENTE = "cliente/gasolinera";

export const GasolineraList = () => {
  const [gasStations, setGasStations] = useState([]);
  const [gasolineraCliente, setGasolineraCliente] = useState();
  const [resultadosBusqueda, setResultadosBusqueda] = useState(gasStations);
  const [busqueda, setBusqueda] = useState("");
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const { token, nombre, apellido } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const fetchGasStationsData = useCallback(async () => {
    setLoading(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_VEHICULOS, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setGasStations(data);
          setResultadosBusqueda(data);
        }
        const response2 = await axiosApi.get(URL_GET_GASOLINERA_CLIENTE, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response2?.data?.code === 200) {
          let data = response2.data.data;
          setGasolineraCliente(data.id);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const orderData =
    (asc, index, gasolineras = null) =>
    () => {
      let data;
      if (!gasolineras) {
        data = [...gasStations];
      } else {
        data = [...gasolineras];
      }

      switch (index) {
        case 0:
          index = "id";
          break;
        case 1:
          index = "nombre";
          break;
        case 2:
          index = "provincia";
          break;
        case 3:
          index = "p_diesel";
          break;
        case 4:
          index = "p_gasolina";
          break;
        default:
          break;
      }

      if (asc) {
        data.sort((a, b) => {
          if (a[index] < b[index]) {
            return -1;
          }
          if (a[index] > b[index]) {
            return 1;
          }
          return 0;
        });
      } else {
        data.sort((a, b) => {
          if (a[index] > b[index]) {
            return -1;
          }
          if (a[index] < b[index]) {
            return 1;
          }
          return 0;
        });
      }
      setGasStations(data);
    };

  useEffect(() => {
    fetchGasStationsData();
  }, [fetchGasStationsData]);

  const handleSearch = (e) => {
    e.preventDefault();
    let value = document.getElementById("buscadorGasolinera").value;
    setBusqueda(value);
    filtrar(value);
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filtrar = (terminoBusqueda) => {
    if (terminoBusqueda.length === 0) {
      setFiltered(false);
      return;
    }

    let busquedaNormalizado = removeAccents(terminoBusqueda);
    let result = gasStations.filter((elemento) => {
      // Removemos los acentos de los campos en los que vamos a buscar
      let nombre = removeAccents(elemento.nombre);
      let localidad = removeAccents(elemento.localidad);
      let provincia = removeAccents(elemento.provincia);
      if (
        nombre
          .toString()
          .toLowerCase()
          .includes(busquedaNormalizado.toLowerCase()) ||
        localidad
          .toString()
          .toLowerCase()
          .includes(busquedaNormalizado.toLowerCase()) ||
        provincia
          .toString()
          .toLowerCase()
          .includes(busquedaNormalizado.toLowerCase())
      ) {
        return elemento;
      }
    });
    setResultadosBusqueda(result);
    setFiltered(true);
  };

  const changeFavorita = async ({ target }) => {
    const id = target.value;
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.put(
          URL_GET_GASOLINERA_CLIENTE,
          {
            id_gas: id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response?.data?.code === 200) {
          setGasolineraCliente(id);
          toast(t("datos.gasolinera.favorita.result.ok"), {
            icon: <LogoPetroprix />,
          });
        } else {
          toast(t("datos.gasolinera.favorita.result.error"), {
            icon: <LogoPetroprix />,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <Col sm={12} lg={9}>
              <h1>
                <Link to={`/dados`} className="no-decoration">
                  &lt;
                </Link>
                {t("datos.gasolinera.list.h1")}
              </h1>
            </Col>
            <Col sm={12} lg={3}>
              <Form
                className="search"
                onSubmit={handleSearch}
                autoComplete="off"
              >
                <InputGroup className="mb-2">
                  <InputGroup.Text>
                    <span className="material-icons-outlined">search</span>
                  </InputGroup.Text>
                  <FormControl
                    type="text"
                    placeholder={t("datos.gasolinera.list.buscador")}
                    className="no-border-left"
                    id="buscadorGasolinera"
                    name="searchText"
                    onChange={handleSearch}
                    autoComplete="off"
                  />
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5 py-1">
          <Row className="content-section">
            {loading ? (
              <div
                className="datos-map"
                style={{ height: "9em", width: "100%" }}
              >
                <LoaderPetroprix type="small" position="absolute" />
              </div>
            ) : (
              <Form>
                {(filtered && resultadosBusqueda.length === 0) ||
                gasStations.length === 0 ? (
                  <p className="text-center">
                    {t("datos.gasolineras.list.table.no-results")}
                  </p>
                ) : (
                  <TablaGasolineras
                    data={filtered ? resultadosBusqueda : gasStations}
                    gasolineraCliente={gasolineraCliente}
                    changeFavorita={changeFavorita}
                    orderData={orderData}
                  />
                )}
              </Form>
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
