// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-error-page {
  padding-top: 3.06em;
}

.error-page-logo {
  float: left;
  margin-top: 3.07em;
  width: 194px;
  height: 43px;
}

.error-section {
  margin-top: 5.12em;
}

.error-text {
  font-weight: 600;
}

.error-text-red {
  color: #db0b27;
}

@media (min-width: 992px) {
  .error-text {
    margin-top: 4em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/feedback/error404.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".content-error-page {\n  padding-top: 3.06em;\n}\n\n.error-page-logo {\n  float: left;\n  margin-top: 3.07em;\n  width: 194px;\n  height: 43px;\n}\n\n.error-section {\n  margin-top: 5.12em;\n}\n\n.error-text {\n  font-weight: 600;\n}\n\n.error-text-red {\n  color: #db0b27;\n}\n\n@media (min-width: 992px) {\n  .error-text {\n    margin-top: 4em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
