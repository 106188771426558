import { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Navbar,
  Row,
} from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import login_img from "../../assets/images/login-img.svg";
import "./login.css";
import { Link } from "react-router-dom";
import Feedback from "react-bootstrap/esm/Feedback";
import "animate.css";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { MiniLoader } from "../ui/MiniLoader";
import { HelpInfo } from "../ui/HelpInfo";

const URL_RESTABLECER = "login/restablecer";

export const RecoveryScreen = () => {
  const [loading, setLoading] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [numLogins, setNumLogins] = useState(0);
  const [username, setUsername] = useState("");
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const [captchaValid, setCaptchaValid] = useState(null);
  const captchaRef = useRef(null);
  const { t } = useTranslation();
  const [telefono, setTelefono] = useState(t("ayuda.telefono"));
  const [email, setEmail] = useState(t("ayuda.email"));

  const [succesMsg, setSuccesMsg] = useState(t("recovery-pass.alert.send"));
  const [errorMsg, setErrorMsg] = useState("");

  const onChange = () => {
    if (captchaRef.current.getValue()) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setEnviado(false);
    setValidated(false);
    setLoading(true);
    setErrorMsg("");
    setError(false);
    setNumLogins(numLogins + 1);

    if (username === "" || !username.includes("@") || !username.includes(".")) {
      return;
    }

    if (!captchaValid) {
      setCaptchaValid(false);
      setLoading(false);
      return;
    }

    let data = {
      email: username,
      captcha: captchaRef.current.getValue(),
    };

    setValidated(true);

    if (form.checkValidity() !== false && captchaValid) {
      try {
        if (process.env.REACT_APP_URL_API) {
          const response = await axiosApi.post(URL_RESTABLECER, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response?.data?.code === 200) {
            setSuccesMsg(response.data.message);
            setUsername("");
            setValidated(false);
            setEnviado(true);
            setError(false);
            setLoading(false);
          } else {
            setError(true);
            setErrorMsg(response.data.message);
            setEnviado(true);
            setCaptchaValid(null);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error(error);
        setEnviado(true);
        setUsername("");
        setError(true);
        setCaptchaValid(null);
        setLoading(false);
      } finally {
        if (captchaRef.current) {
          captchaRef.current.reset();
        }
      }
    }
  };

  const fetchInfoContacto = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API) {
        const response = await axiosApi.get("infoPetroprix", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setTelefono(data.telefono);
          setEmail(data.email_informacion);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchInfoContacto();
  }, []);

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="menu px-1"
          style={{ display: "flex" }}
        >
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top login-page-logo"
              alt={t("login.menu.text")}
            />
          </Navbar.Brand>
          <HelpInfo telefono={telefono} email={email} />
        </Navbar>
      </Container>
      <Container className="content-login-page">
        <Container className="login-header-container">
          <header className="d-none d-lg-block">
            <img
              src={petroprix_logo}
              className="d-inline-block align-left login-page-logo"
              alt={t("login.menu.text")}
            />
          </header>
          <HelpInfo
            className={"d-none d-lg-block"}
            telefono={telefono}
            email={email}
          />
        </Container>
        <Container className="login-container py-1">
          <Row className="content-section login-section">
            <Col xs={12} lg={{ span: 12, order: 1 }}>
              <h1>
                {t("login.header.h1")}
                <span className="red-text">{t("login.header.h1.red")}</span>
              </h1>
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 1, order: 3 }}
              className="d-none d-lg-block"
            >
              <Image src={login_img} fluid="true" className="mt-5" />
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 2, order: 3 }}
              className="animate__animated animate__fadeInRight"
            >
              <h2 className="mt-5">
                <Link to={`/login`} className="no-decoration">
                  &lt;
                </Link>{" "}
                {t("recovery-pass.h2")}
              </h2>
              {enviado ? (
                error ? (
                  <Alert variant="danger-login">
                    {t("recovery-pass.alert.error")}
                    <b>{t("recovery-pass.alert.error.b")}</b>
                  </Alert>
                ) : (
                  <Alert variant="primary-login">{succesMsg}</Alert>
                )
              ) : (
                numLogins === 0 && (
                  <Alert variant="warning-login">
                    {t("recovery-pass.alert.info")}
                    <b>{t("recovery-pass.alert.info.b")}</b>
                    {t("recovery-pass.alert.info-2")}
                  </Alert>
                )
              )}

              {captchaValid === false && (
                <Alert variant="danger-login">
                  <b>{t("login.form.captcha.not-valid")}</b>
                </Alert>
              )}
              <Form
                noValidate
                validated={validated}
                className="login-form"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <FloatingLabel
                  label={t("recovery-pass.form.email")}
                  className="mt-4"
                >
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder={t("recovery-pass.form.email")}
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  />
                  <Feedback type="invalid">
                    {t("recovery-pass.form.email.invalid")}
                  </Feedback>
                </FloatingLabel>

                <div className="d-flex mt-3 recaptcha">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={SITE_KEY}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="d-grid gap-2 mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className="form-login-btn"
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <MiniLoader />
                    ) : (
                      t("recovery-pass.form.btn-recuperar")
                    )}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
