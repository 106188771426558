// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prom-info-text {
  font-size: 0.8em;
  text-align: center;
}

.prom-download-logos div {
  display: inline-flex;
  justify-content: space-evenly;
}

.prom-download-logos img {
  max-width: 11em;
  width: 11em;
}

.promo-img {
  width: 100%;
}

.promo-img:hover {
  cursor: pointer;
}

.prom-modal-title {
  font-size: 1.1em;
  font-weight: 500 !important;
  text-align: center;
}

.prom-img-banner {
  cursor: pointer;
}

/* BREAKPOINT TABLETS*/
@media (min-width: 768px) {
  .prom-img-banner {
    max-width: unset;
    width: 90%;
  }
}
/* BREAKPOINT ESCRITORIO */
@media (min-width: 992px) {
  .promo-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .promo-img {
    transition: 0.3s;
    aspect-ratio: 18/9;
    max-width: 90%;
    max-height: 90%;
    padding: 1em;
  }

  .promo-img:hover {
    filter: brightness(0.9);
    padding: 0.5em;
    transition: 0.3s;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/promociones/promociones.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA,sBAAsB;AACtB;EACE;IACE,gBAAgB;IAChB,UAAU;EACZ;AACF;AACA,0BAA0B;AAC1B;EACE;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,cAAc;IACd,gBAAgB;EAClB;AACF","sourcesContent":[".prom-info-text {\n  font-size: 0.8em;\n  text-align: center;\n}\n\n.prom-download-logos div {\n  display: inline-flex;\n  justify-content: space-evenly;\n}\n\n.prom-download-logos img {\n  max-width: 11em;\n  width: 11em;\n}\n\n.promo-img {\n  width: 100%;\n}\n\n.promo-img:hover {\n  cursor: pointer;\n}\n\n.prom-modal-title {\n  font-size: 1.1em;\n  font-weight: 500 !important;\n  text-align: center;\n}\n\n.prom-img-banner {\n  cursor: pointer;\n}\n\n/* BREAKPOINT TABLETS*/\n@media (min-width: 768px) {\n  .prom-img-banner {\n    max-width: unset;\n    width: 90%;\n  }\n}\n/* BREAKPOINT ESCRITORIO */\n@media (min-width: 992px) {\n  .promo-content {\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n  }\n\n  .promo-img {\n    transition: 0.3s;\n    aspect-ratio: 18/9;\n    max-width: 90%;\n    max-height: 90%;\n    padding: 1em;\n  }\n\n  .promo-img:hover {\n    filter: brightness(0.9);\n    padding: 0.5em;\n    transition: 0.3s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
