import React, { useState } from "react";
import { Form, Button, Row, } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

// creating functional component ans getting props from app.js and destucturing them
export const FlotaStepOne = ({ nextStep, handleFormData, values }) => {

  //creating error state for validation
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    let tipo = e.target.value;
    
    // checking if value of first name and last name is empty show error else take to step 2
    if (tipo === '') {
      setError(true);
    } else {
      values.tipoPago=tipo;
      nextStep();
    }
  };

  return (
    <>
      <h1 className="flotas-registro centered">{t('flotas.registro-flota.step-1.h1')}</h1>
      <p>{t('flotas.registro-flota.step-1.p')}</p>
      <Form className="flotas-registro-nueva" autoComplete="off">
        <Row>
          <div className="gap-2 mt-3 flotas-btn-step1">
            <Button as="input" variant="flota-registro-tipo" size="lg" value={t('flotas.registro-flota.step-1.btn-prepago')} onClick={submitFormData}/>
            <Button as="input" variant="flota-registro-tipo" size="lg" value={t('flotas.registro-flota.step-1.btn-credito')} onClick={submitFormData}/>
          </div>
        </Row>
      </Form>
    </>
  );
};
