// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.facturas span.input-group-text {
  border: none;
}

table.facturas > :not(:first-child) {
  border-top: none;
}

table.facturas th:last-child {
  text-align: center;
}

table.facturas tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

table.facturas td {
  padding-bottom: 1.75em;
  padding-top: 1.75em;
  vertical-align: middle;
}

table.facturas td:nth-child(2) {
  text-transform: capitalize;
}

.facturas-periodo,
.facturas-fecha,
.facturas-importe {
  text-align: center;
}

.facturas-periodo,
.facturas-importe {
  font-weight: 600;
}

.facturas-fecha {
  color: #a098ae;
}

.factura-download {
  display: flex;
}

.factura-pdf {
  color: #db0b27 !important;
  cursor: pointer;
  font-weight: 700;
}

.factura-pdf > .material-icons {
  display: inline;
  vertical-align: middle;
}

/* BREAKPOINT TABLETS*/
@media (min-width: 768px) {
  table.facturas th {
    text-align: center;
  }

  .factura-pdf {
    text-align: center;
  }
}

/* BREAKPOINT ESCRITORIO */
@media (min-width: 992px) {
  table.facturas tr,
  table.facturas td {
    border-bottom: none;
  }

  table.facturas thead {
    border-bottom: 1px solid #dbdbdb;
  }

  table.facturas th,
  table.facturas th:last-child,
  table.facturas td {
    text-align: left;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/facturas/facturas.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;;EAGE,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA,sBAAsB;AACtB;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA,0BAA0B;AAC1B;EACE;;IAEE,mBAAmB;EACrB;;EAEA;IACE,gCAAgC;EAClC;;EAEA;;;IAGE,gBAAgB;EAClB;AACF","sourcesContent":["table.facturas span.input-group-text {\n  border: none;\n}\n\ntable.facturas > :not(:first-child) {\n  border-top: none;\n}\n\ntable.facturas th:last-child {\n  text-align: center;\n}\n\ntable.facturas tr {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n}\n\ntable.facturas td {\n  padding-bottom: 1.75em;\n  padding-top: 1.75em;\n  vertical-align: middle;\n}\n\ntable.facturas td:nth-child(2) {\n  text-transform: capitalize;\n}\n\n.facturas-periodo,\n.facturas-fecha,\n.facturas-importe {\n  text-align: center;\n}\n\n.facturas-periodo,\n.facturas-importe {\n  font-weight: 600;\n}\n\n.facturas-fecha {\n  color: #a098ae;\n}\n\n.factura-download {\n  display: flex;\n}\n\n.factura-pdf {\n  color: #db0b27 !important;\n  cursor: pointer;\n  font-weight: 700;\n}\n\n.factura-pdf > .material-icons {\n  display: inline;\n  vertical-align: middle;\n}\n\n/* BREAKPOINT TABLETS*/\n@media (min-width: 768px) {\n  table.facturas th {\n    text-align: center;\n  }\n\n  .factura-pdf {\n    text-align: center;\n  }\n}\n\n/* BREAKPOINT ESCRITORIO */\n@media (min-width: 992px) {\n  table.facturas tr,\n  table.facturas td {\n    border-bottom: none;\n  }\n\n  table.facturas thead {\n    border-bottom: 1px solid #dbdbdb;\n  }\n\n  table.facturas th,\n  table.facturas th:last-child,\n  table.facturas td {\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
