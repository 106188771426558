import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import flota_img from "../../assets/images/flota-img.svg";

import study_icon from "../../assets/images/study-icon.svg";
import pdf_icon from "../../assets/images/pdf-icon.svg";
import upload_icon from "../../assets/images/upload-icon.svg";
import warning_icon from "../../assets/images/warning-icon.svg";
import "./flotas.css";
import { FlotasActions } from "./FlotasActions";
import { Menu } from "../ui/Menu";
import { Header } from "../ui/Header";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { TablaVehiculosFlota } from "./TablaVehiculosFlota";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { FlotasInfo } from "./FlotasInfo";
import { changeFlota } from "../../store/auth";
import { isMobile } from "react-device-detect";
import { startLogout } from "../../store/auth";
import { ValidateToken } from "../../helpers/validateToken";

const URL_GET_VEHICULOS_FLOTA = "flotas/vehiculos/";

export const FlotasScreen = () => {
  const { token, nombre, apellido, flotas, flotaActiva, controlKmFlotaActiva } =
    useSelector((state) => state.auth);
  const [hasFlotas, setHasFlotas] = useState(flotas);
  const [controlKM, setControlKM] = useState(
    controlKmFlotaActiva === 1 ? true : false
  );
  const [loadingVehiculos, setLoadingVehiculos] = useState(true);
  const [vehiculosFlota, setVehiculosFlota] = useState([]);
  const [resultadosBusqueda, setResultadosBusqueda] = useState(vehiculosFlota);
  const [busqueda, setBusqueda] = useState("");
  const [filtered, setFiltered] = useState(false);
  const [flotaVehiculos, setFlotaVehiculos] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchVehiculosFlotaActiva = useCallback(
    async (id_cuenta = flotaActiva.id_cuenta) => {
      if (!isMobile) {
        document.getElementById("buscadorFlotas").value = "";
      }
      setBusqueda("");
      setResultadosBusqueda([]);
      setVehiculosFlota([]);
      setFiltered(false);
      setLoadingVehiculos(true);
      setFlotaVehiculos(id_cuenta);
      try {
        if (process.env.REACT_APP_URL_API && ValidateToken()) {
          const response = await axiosApi.get(
            URL_GET_VEHICULOS_FLOTA + id_cuenta,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          if (response?.data?.code === 200) {
            let data = response.data.data;
            setVehiculosFlota(data);
            setLoadingVehiculos(false);
          } else {
            setLoadingVehiculos(false);
          }
        } else {
          dispatch(startLogout("expired"));
        }
      } catch (error) {
        console.error(error);
        setLoadingVehiculos(false);
      }
    },
    []
  );

  useEffect(() => {
    if (hasFlotas && flotaActiva) {
      if (flotaVehiculos !== flotaActiva.id_cuenta) {
        fetchVehiculosFlotaActiva(flotaActiva.id_cuenta);
      }
    }
  }, [flotaActiva, hasFlotas]);

  useEffect(() => {
    dispatch(changeFlota(flotaActiva, token));
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    let value = document.getElementById("buscadorFlotas").value;
    setBusqueda(value);
    filtrar(value);
  };

  const filtrar = (terminoBusqueda) => {
    let result = vehiculosFlota.filter((elemento) => {
      if (
        elemento.matricula
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.tipo_vehiculo
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.tipo_combustible
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.credito_definido
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.credito_consumido
          ?.toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        ("activo".includes(terminoBusqueda.toLowerCase()) &&
          elemento.activo === 1) ||
        ("inactivo".includes(terminoBusqueda.toLowerCase()) &&
          elemento.activo === 0)
      ) {
        return elemento;
      }
    });
    setResultadosBusqueda(result);
    setFiltered(true);
  };

  const orderData = (asc, index) => () => {
    let data;
    if (!filtered) {
      data = [...vehiculosFlota];
    } else {
      data = [...resultadosBusqueda];
    }

    switch (index) {
      case 0:
        index = "matricula";
        break;
      case 1:
        index = "tipo_vehiculo";
        break;
      case 2:
        index = "tipo_combustible";
        break;
      case 3:
        index = "credito_definido";
        break;
      case 4:
        index = "credito_consumido";
        break;
      case 5:
        index = "activo";
        break;
      default:
        break;
    }

    if (asc) {
      data.sort((a, b) => {
        if (a[index] < b[index]) {
          return -1;
        } else if (a[index] > b[index]) {
          return 1;
        } else if (a[index] === null) {
          return 1;
        } else if (b[index] === null) {
          return -1;
        } else {
          return 0;
        }
      });
    } else {
      data.sort((a, b) => {
        if (a[index] > b[index]) {
          return -1;
        } else if (a[index] < b[index]) {
          return 1;
        } else if (a[index] === null) {
          return 1;
        } else if (b[index] === null) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    filtered ? setResultadosBusqueda(data) : setVehiculosFlota(data);
  };

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row
            className={`page-header flotas ${
              hasFlotas === "1" ? "has-flotas" : ""
            } pb-4 ${flotaActiva?.activo === "0" ? "mt-5" : "mt-3"}`}
          >
            {/* <Col sm={12} lg={{ span: 7, order: 1 }}>
              <h1 className="d-xs-block d-md-none">{t("flotas.header.h1")}</h1>
            </Col> */}
            {hasFlotas === "1" && (
              <>
                {isMobile ? (
                  <Col
                    sm={12}
                    md={{ offset: 3, span: 7 }}
                    className="flotas-actions d-xs-block d-lg-none"
                  >
                    {/* Visibilidad en móviles */}

                    <div style={{ overflowX: "scroll", marginBottom: "1rem" }}>
                      <FlotasInfo />
                    </div>

                    <Row style={{ overflowX: "scroll", marginBottom: "1rem" }}>
                      <FlotasActions
                        controlKM={controlKM}
                        setControlKM={setControlKM}
                      />
                    </Row>
                  </Col>
                ) : (
                  <>
                    <Col
                      lg={{ span: 7, order: 1 }}
                      className="d-none d-lg-block step12"
                    >
                      <FlotasInfo />
                    </Col>
                    <Col
                      lg={{ span: 5, order: 2 }}
                      className="flotas-actions d-none d-lg-block step13"
                    >
                      <FlotasActions
                        controlKM={controlKM}
                        setControlKM={setControlKM}
                      />
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
          {hasFlotas === "1" && !isMobile && (
            <Row className={`page-header flotas pb-4 mt-2 flex-row-reverse `}>
              <Col sm={12} lg={{ span: 3, order: 3 }}>
                <Form
                  className="search"
                  onSubmit={handleSearch}
                  autoComplete="off"
                >
                  <InputGroup className="mb-1">
                    <InputGroup.Text>
                      <span className="material-icons-outlined">search</span>
                    </InputGroup.Text>
                    <FormControl
                      type="text"
                      placeholder={t("facturas.header.buscador")}
                      className="no-border-left"
                      id="buscadorFlotas"
                      name="searchText"
                      onChange={handleSearch}
                      autoComplete="off"
                    />
                  </InputGroup>
                </Form>
              </Col>
            </Row>
          )}
        </Container>
        <Container className="mt-1">
          <Row
            className={`content-section flotas step14 ${
              hasFlotas === "1" ? "content-has-flotas" : ""
            }`}
          >
            {hasFlotas === "1" ? (
              !loadingVehiculos ? (
                vehiculosFlota.length > 0 ? (
                  <TablaVehiculosFlota
                    vehiculos={filtered ? resultadosBusqueda : vehiculosFlota}
                    token={token}
                    permisos={flotaActiva.permisos}
                    activo={flotaActiva.activo}
                    onChange={fetchVehiculosFlotaActiva}
                    orderData={orderData}
                  />
                ) : (
                  <Alert variant="warning" className="mt-5">
                    {t("flotas.table.no-vehiculos")}
                  </Alert>
                )
              ) : (
                <LoaderPetroprix />
              )
            ) : hasFlotas === "0" ? (
              <Row className="no-flota mt-4">
                <Col xs={12} lg={{ span: 8, offset: 2 }}>
                  <Image
                    src={flota_img}
                    fluid="true"
                    className="flotas-image-info"
                  />
                  {/* <h2>{t("flotas.no-flotas.h2")}</h2> */}
                  <h2>{t("flotas.no-flotas.h2.no-contratado")}</h2>
                  <div className="d-grid gap-2">
                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                      <p>
                        {t("flotas.no-flotas.p.contratar.1")}
                        <strong>{t("flotas.no-flotas.p.contratar.2")}</strong>
                      </p>
                    </Col>
                    {/* <Button
                      variant="primary"
                      className="flotas-register-btn"
                      size="lg"
                      href="/flotas/nueva-flota"
                    >
                      {t("flotas.no-flotas.btn-registrar")}
                    </Button> */}
                  </div>
                </Col>
              </Row>
            ) : hasFlotas === "0" ? (
              <Row className="no-flota">
                <Col xs={12} lg={{ span: 8, offset: 2 }}>
                  <Image
                    src={flota_img}
                    fluid="true"
                    className="flotas-image-info"
                  />
                  <Row className="mt-5 flotas-status">
                    <span className="flotas-study-icon">
                      <img src={warning_icon} alt="" />
                    </span>
                    <p className="flotas-study">
                      {t("flotas.no-flotas.p.aval-no")}
                    </p>
                  </Row>
                  <Row className="mt-3">
                    <label
                      for="upload-document"
                      className="mt-3 btn btn-outline-primary btn-lg flotas-document-btn flotas-register-btn"
                    >
                      <img src={upload_icon} alt="" />{" "}
                      {t("flotas.no-flotas.btn-subir")}
                    </label>
                    <input type="file" name="document" id="upload-document" />
                    <Button
                      variant="primary"
                      className="mt-3 flotas-document-btn flotas-register-btn"
                      size="lg"
                    >
                      <img src={pdf_icon} alt="" />{" "}
                      {t("flotas.no-flotas.btn-descargar")}
                    </Button>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row className="no-flota">
                <Col xs={12} lg={{ span: 8, offset: 2 }}>
                  <Image
                    src={flota_img}
                    fluid="true"
                    className="flotas-image-info"
                  />
                  <Row className="mt-5 flotas-status">
                    <span className="flotas-study-icon">
                      <img src={study_icon} alt="" />
                    </span>
                    <p className="flotas-study">
                      {t("flotas.no-flotas.p.aval-si-1")}
                      <b>Neumáticos Rueda S.L.</b>
                      {t("flotas.no-flotas.p.aval-si-2")}
                      <span className="flotas-status-process">
                        {t("flotas.no-flotas.p.aval.en-estudio")}
                      </span>
                    </p>
                  </Row>
                </Col>
              </Row>
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
