import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import success_icon from "../../assets/images/success-icon.svg";
import error_icon from "../../assets/images/error-icon.svg";
import { Menu } from "../ui/Menu";
import { Header } from "../ui/Header";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fleets_icon from "../../assets/images/fleets-icon.svg";

export const EstadoScreen = ({ status }) => {
  const [estado, setEstado] = useState(status === "ok" ? true : false);

  const { t } = useTranslation();
  const { token, nombre, apellido } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const secondsRedirect = 5;
  const [timeLeft, setTimeLeft] = useState(secondsRedirect);

  //Creamos un intervalo que se ejecuta cada segundo
  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 1) {
        setTimeLeft(timeLeft - 1);
      } else {
        navigate("/flotas/mis-flotas");
      }
    }, 1000);
    //Limpiamos el intervalo cuando el componente se desmonta
    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <Col xs={12} sm={12} lg={{ span: 9, order: 1 }}>
              <h1>{t("pago.estado.h1")}</h1>
            </Col>
          </Row>
        </Container>
        <Container className="py-1 mt-5">
          <Row className="content-section">
            <Image
              src={estado ? success_icon : error_icon}
              className="pagos-estado-img"
            />
            <div className="pagos-estado">
              <p className="pagos-estado-text mt-3">
                {estado ? t("pago.estado.ok") : t("pago.estado.nok")}
              </p>
              <p className="pagos-estado-info mt-3">
                {t("pago.estado.info")} {timeLeft} {t("pago.estado.info.2")}
              </p>
              <p className="pagos-estado-info-adicional mt-2">
                {t("pago.estado.info.b")}
              </p>
              <button
                className="pagos-estado-info btn btn-primary mt-2"
                onClick={() => {
                  navigate("/flotas/mis-flotas");
                }}
              >
                {t("pago.estado.btn")}
              </button>
            </div>
          </Row>
        </Container>
      </Container>
    </>
  );
};
