// Funcion para formatear un numero de telefono en grupos de 3 digitos sin eliminar los caracteres no numericos y siendo tan largo como se quiera
//
export function formatTelefono(telefono) {
  if (!telefono) return "";
  let formatted = "";
  let count = 0;
  for (let i = 0; i < telefono.length; i++) {
    if (count === 3) {
      formatted += " ";
      count = 0;
    }
    formatted += telefono[i];
    //Si es un simbolo no numerico no incrementamos el contador
    if (isNaN(telefono[i])) continue;
    count++;
  }
  return formatted;
}
